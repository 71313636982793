import { ApiHelper } from '../../apiHelper/ApiHelper';
import Product from '../../components/Product';
import './styles/Sale.scss'
import { useEffect, useState } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutComponent from '../../components/CheckoutComponent';
import { useAlert } from 'react-alert';

const stripePromise = loadStripe('pk_test_51NwowlL4d2RQfnnos1H26PyYZa1ses63IwYRJqt5ZJ6TIx1DPS8sl5HNSPB1873CZnHWGundxxaAhRw1moKr0UhX00XNUQXDbr');

export default function Sale() {
    const[bounce, setBounce] = useState(false);
    const[open, setOpen] = useState(false);
    const[cart, setCart] = useState<Array<number>>([]);
    const[prices, setPrices] = useState<Array<number>>([0, 0, 0, 0]);
    const[prevPrices, setPrevPrices] = useState<Array<number | null>>([null, null, null, null]);
    const[bought, setBought] = useState<Array<boolean>>([false, false, false, false]);
    const[total, setTotal] = useState(0);
    const[clientSecret, setClientSecret] = useState("");

    const alert = useAlert();

    const options = {
        clientSecret: clientSecret
    };

    const names = ["Kurz - fyzika", "Kurz - chemie", "Kurz - biologie", "Kompletní kurz"];

    const api = new ApiHelper();

    async function fetch() {
        try {
            const res = await api.saleApi.getDataCreate();
            const proc = await res.json();

            const pricesRes = [0, 0, 0, 0];
            const prevPricesRes = [null, null, null, null];
            const boughtRes = [false, false, false, false];

            proc.courses.forEach((e) => {
                pricesRes[e.courseType] = e.price;
                prevPricesRes[e.courseType] = e.prevPrice;
                boughtRes[e.courseType] = e.bought;
            });

            setPrices(pricesRes);
            setPrevPrices(prevPricesRes);
            setBought(boughtRes);
        } catch (x : any) {
            api.processErrors(x)
        }
    }

    async function fetchPayment(t) {
        try {
            if (t <= 0) {
                alert.info("Košík je prázdný") 
                return
            }
            const res = await api.saleApi.paymentRequestCreate({amount: t, courses: cart})
            const proc = await res.json();

            setClientSecret(proc.key)
            console.log(proc.key)
        } catch (x : any) {
            api.processErrors(x)
        }
    }
    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        let t = 0

        cart.forEach((i) =>
            t += prices[i]
        )

        setTotal(t);
        setClientSecret("")
    }, [cart])

    function addToCartClicked(i : number) {
        setBounce(true);        
        setCart((cart) => [...cart, i]);
    }

    function isDisabled(i : number) {
        if(i === 1) {
            return true;
        }

        if (cart.includes(i)) {
            return true;
        }

        if (cart.includes(3) && (i !== 3))
        {
            return true;
        }
        
        if ((cart.length > 0) && (!cart.includes(3)) && (i === 3)) {
            return true;
        }

        if ((bought[0] && bought[2]) || bought[3]) {
            return true;
        }
        
        if (bought[i]) {
            return true;
        }

        return false;
    }

    function removeFromCartClicked(i : number) {
        setCart((cart) => cart.filter((x) => x !== i));
    }

    return (
        <div className="sale">
            <Product title={names[0]} number={0} disabled={isDisabled(0)} img="/images/pages/image.png" price={prices[0]} prevPrice={prevPrices[0]} clicked={addToCartClicked}>
                <ul>
                    <li>
                        20+ lekcí
                    </li>
                    <li>
                        20+ testů
                    </li>
                    <li>
                        250+ procvičovacích příkladů
                    </li>
                    <li>
                        Videa s řešenými příklady
                    </li>
                    <li>
                        Studijní plány
                    </li>
                    <li>
                        Odpovědi na dotazy
                    </li>
                </ul>
            </Product>
            <Product title={names[1]} number={1} disabled={isDisabled(1)} img="/images/pages/image.png" price={prices[1]} prevPrice={prevPrices[1]} clicked={addToCartClicked}>
                <ul>
                    <li>
                        20+ lekcí
                    </li>
                    <li>
                        20+ testů
                    </li>
                    <li>
                        250+ procvičovacích příkladů
                    </li>
                    <li>
                        Videa s řešenými příklady
                    </li>
                    <li>
                        Sudijní plány
                    </li>
                    <li>
                        Odpovědi na dotazy
                    </li>
                </ul>
            </Product>
            <Product title={names[2]} number={2} disabled={isDisabled(2)} img="/images/pages/image.png" price={prices[2]} prevPrice={prevPrices[2]} clicked={addToCartClicked} >
                <ul>
                    <li>
                        20+ lekcí
                    </li>
                    <li>
                        20+ testů
                    </li>
                    <li>
                        250+ procvičovacích příkladů
                    </li>
                    <li>
                        Vysvětlovací videa
                    </li>
                    <li>
                        Studijní plány
                    </li>
                    <li>
                        Odpovědi na dotazy
                    </li>
                </ul>
            </Product>
            <Product title={names[3]} number={3} disabled={isDisabled(3)} img="/images/pages/image.png" price={prices[3]} prevPrice={prevPrices[3]} clicked={addToCartClicked} >
                <ul>
                    <li>
                        40+ lekcí
                    </li>
                    <li>
                        40+ testů
                    </li>
                    <li>
                        Všechny procvičovací příklady
                    </li>
                    <li>
                        Všechna videa
                    </li>
                    <li>
                        Všechny studijní plány
                    </li>
                    <li>
                        Odpovědi na dotazy
                    </li>
                </ul>
            </Product>

            <div className={"cart " + (open ? "open" : "")}>
                <img onClick={() => setOpen((o : any) => !o)} onAnimationEnd={() => setBounce(false)} className={"tag " + (bounce ? "bounce " : "")} src="/images/pages/cart.png" alt="" />
                <h1> 
                    Souhrn objednávky
                </h1>
                <div className="holder">
                    <div className="products">
                        {
                            (!(cart.length > 0)) ? 
                            <>
                                <h2 className="empty">Košík je prázdný</h2>
                            </>                       
                            :
                            <>
                                {
                                    cart.map((value, i) => {
                                        return(
                                            <div className="line">
                                                <h2>
                                                    {names[value]}
                                                </h2>
                                                <div className="spacer"></div>
                                                <h2>
                                                    {prices[value]} Kč
                                                </h2>
                                                <img onClick={() => removeFromCartClicked(value)} src="/images/pages/cross.png" alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }

                        <hr />

                        <div className="line">
                            <h2>
                                Celkem
                            </h2>
                            <div className="spacer"></div>
                            <h2>
                                {total} Kč
                            </h2>
                        </div>
                    </div>

                    {clientSecret !== "" ?
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutComponent clientSecret={clientSecret}/>
                        </Elements>
                        :
                        <div onClick={() => fetchPayment(total)} className={"pay" + ((total === 0) ? " disabled" : "")}>
                            K platbě
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
