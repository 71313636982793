import { useEffect, useState } from 'react';
import FormField from '../../components/FormField';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import './styles/User.scss'
import { ApiHelper } from '../../apiHelper/ApiHelper';

export default function User() {
    const[name, setName] = useState("");
    const[surname, setSurname] = useState("");
    const[password, setPassword] = useState("");
    const[passwordNew, setPasswordNew] = useState("");
    const[passwordRep, setPasswordRep] = useState("");
    const[fetched, setFetched] = useState(false);
    const alert = useAlert();
    const navigate = useNavigate();

    const api = new ApiHelper();

    async function fetch() {
        try {
            const res = await api.userApi.getDataList();
            const proc = await res.json();

            setName(proc.name);
            setSurname(proc.surname);
        } catch (x : any) {
            api.processErrors(x);
        }
    }

    useEffect(() => {
        if (!fetched) {
            fetch();
            setFetched(false);
        }
    }, []);

    async function processData() {
        try {
            await api.userApi.changeDataCreate({name: name, surname: surname});
            alert.success("Data byla úspěšně změněna");
            fetch();
        } catch (x : any) {
            api.processErrors(x)
        }
    }

    async function processPassword() {
        if (passwordNew !== passwordRep) {
            alert.error("Hesla nejsou shodná");
            return;
        }

        try {
            await api.userApi.changePasswordCreate({password: password, newPassword: passwordNew});
            alert.success("Heslo bylo úspěšně změněno");
        } catch (x : any) {
            api.processErrors(x)
        }
    }

    function processLogOut() {
        localStorage.removeItem("token");
        navigate("/");
    }

    return (
        <div className="user">
            <div className="avatar">
                <img src="/images/pages/avatar.png" alt="" />
            </div>

            <div className="container" >
                <h1 className="title">
                    Změna údajů
                </h1>

                <FormField label="jméno" value={name} handle={setName} />
                <FormField label="příjmení" value={surname} handle={setSurname} />

                <div className={"button"} onClick={() => processData()}>
                    <h2>
                        ZMĚNIT
                    </h2>
                </div>
            </div>

            <div className="container" >
                <h1 className="title">
                    Změna hesla
                </h1>

                <FormField label="původní heslo" value={password} handle={setPassword} password={true} />
                <FormField label="nové heslo" value={passwordNew} handle={setPasswordNew} password={true} />
                <FormField label="heslo znovu" value={passwordRep} handle={setPasswordRep} password={true} />

                <div className={"button"} onClick={() => processPassword()}>
                    <h2>
                        ZMĚNIT
                    </h2>
                </div>
            </div>

            <div className="button logOut" onClick={() => processLogOut()}>
                <h2>
                    ODHLÁSIT SE
                </h2>
            </div>
        </div>
    );
}

