import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './styles/subjectPage.scss'
import Lesson from '../../components/Lesson';
import ProgressCircle from '../../components/ProgressCircle';
import { ApiHelper } from '../../apiHelper/ApiHelper';
import { Subjects } from '../../apiClient/data-contracts';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function getSize(width : number) {
    return ((width < 950) ? 0 : ((width < 1820) ? 1 : 2));
}
  
export default function SubjectPage({subject} : any) {
    const[size, setSize] = useState(getSize(getWindowDimensions().width));
    const[lessons, setLessons] = useState([]);
    const[progress, setProgress] = useState(0);
    const[fetched, setFetched] = useState(false);

    const headers = {"physics": "Fyzika", "chemistry": "Chemie", "biology": "Biologie"}
    const images = {"physics": "atom", "chemistry": "isopropanol", "biology": "cell"}

    const api = new ApiHelper();

    async function fetch() {
        try {
            const res = await api.lessonsApi.getLessonsDetail(subject);
            const proc = await res.json();

            setLessons(proc.lessons);

            const resProg = await api.lessonsApi.getProgressList();
            const procProg = await resProg.json();

            switch (subject) {
                case "physics":
                    setProgress(procProg.physicsProgress);
                    break;

                case "chemistry":
                    setProgress(procProg.chemistryProgress);
                    break;
            
                case "biology":
                    setProgress(procProg.biologyProgress);
                    break;

                default:
                    break;
            }
        } catch (x : any) {
            api.processErrors(x)
        }
    }
  
    useEffect(() => {
        if (!fetched) {
            fetch();
            setFetched(false);
        }

        function handleResize() {
            setSize(getSize(getWindowDimensions().width));
        }
  
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return (
        <>
            <div className="heading">
                <h1>
                    {
                        headers[subject]
                    }
                </h1>
                <ProgressCircle value={progress} img={"/images/components/" + images[subject] + ".png"} />
            </div>
            <div className={"subjectPage subjectPage" + size}>
                {(lessons.length > 0) && lessons.map((l : any) => {
                    return (
                        <Lesson number={l.number.toString()} title={l.title} motto={l.caption} completed={l.isCompleted} locked={!l.isAllowed} />
                    )
                })}
            </div>

            <Link to=".." className="back"><img src="/images/common/back.png" alt="" /></Link>
        </>
    );
}
