import { useDebugValue, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Subject from '../../components/Subject';
import './styles/learningSpace.scss'
import { Lessons } from '../../apiClient/Lessons';
import { RequestParams } from '../../apiClient/http-client';
import { ApiHelper } from '../../apiHelper/ApiHelper';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
  
export default function LearningSpace() {
    const[mobile, setMobile] = useState(getWindowDimensions().width < 800);
    const[progress, setProgress] = useState({physicsProgress: 0, biologyProgress: 0, chemistryProgress: 0});
    const[fetched, setFetched] = useState(false);
    const navigate = useNavigate();
  
    const api = new ApiHelper();

    async function fetch() {

        try {
            const res = await api.lessonsApi.getProgressList();
            
            setProgress(await res.json());
        } catch (x : any) {
            api.processErrors(x);
        }
    }

    useEffect(() => {
        if (!fetched)
        {
            fetch();
            setFetched(true);
        }

        function handleResize() {
            setMobile((getWindowDimensions().width < 750) ? true : false);
        }
  
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return (
        <div className={(mobile) ? "learningSpaceMobile" : "learningSpace"}>
            <Subject onClick={() => navigate("/kurz/fyzika")} title="Fyzika" img="/images/components/atom.png" progress={progress.physicsProgress} mobile={mobile} />
            <Subject onClick={() => null} commingSoon={true} title="Chemie" img="/images/components/isopropanol.png" progress={progress.chemistryProgress} mobile={mobile} />
            <Subject onClick={() => navigate("/kurz/biologie")} title="Biologie" img="/images/components/cell.png" progress={progress.biologyProgress} mobile={mobile} />
        </div>
    );
}
