import React from 'react';
import './App.scss';

import '@aws-amplify/ui-react/styles.css';

import "./fonts/DejaVuSansMono.ttf";
import "./fonts/DejaVuSansMono-Bold.ttf";
import "./fonts/DejaVuSansMono-BoldOblique.ttf";
import "./fonts/DejaVuSansMono-Oblique.ttf";

import { Routes, Route, Outlet } from "react-router-dom";
import Home from './pages/public/Home';
import LearningSpace from './pages/learningSpace/LearningSpace';
import NoMatch from './pages/public/NoMatch';
import SubjectPage from './pages/learningSpace/SubjectPage';
import LessonPage from './pages/learningSpace/LessonPage';
import Menu from './components/Menu';
import Footer from './components/Footer';
import Sale from './pages/public/Sale';
import SignIn from './pages/public/SignIn';
import VideoPlayer from './pages/learningSpace/VideoPlayer';
import DocumentViewer from './pages/learningSpace/DocumentViewer';
import SignUp from './pages/public/SignUp';
import User from './pages/learningSpace/User';

const About = React.lazy(() => import("./pages/public/About"));

export default function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
            <Route
              path="kontakty"
              element={
                <React.Suspense fallback={<>...</>}>
                  <About />
                </React.Suspense>
              }
            />

            <Route
              path="prihlaseni"
              element={
                <React.Suspense fallback={<>...</>}>
                    <SignIn />
                </React.Suspense>
              }
            />

            <Route
              path="registrace"
              element={
                <React.Suspense fallback={<>...</>}>
                    <SignUp />
                </React.Suspense>
              }
            />
          </Route>

          <Route
            path="/kurz"
            element={<InternalLayout />}
          >
            <Route
              path="ucet"
              element={
                <React.Suspense fallback={<>...</>}>
                  <User />
                </React.Suspense>
              }
            />

            <Route
              path="nakup"
              element={
                <React.Suspense fallback={<>...</>}>
                    <Sale />
                </React.Suspense>
              }
            />

            <Route
              index
              element={
                <React.Suspense fallback={<>...</>}>
                  <LearningSpace />
                </React.Suspense>
              }
            />

            <Route
              path="fyzika"
              element={
                <React.Suspense fallback={<>...</>}>
                  <Outlet />
                </React.Suspense>
              }
            >
              <Route index element={<SubjectPage subject="physics" />} />
              <Route path=":lessonNumber" element={
                <React.Suspense fallback={<>...</>}>
                  <Outlet />
                </React.Suspense>

              }>
                <Route index element={<LessonPage subject="physics" />} />

                <Route path="video" element={<VideoPlayer />} />
                <Route path="dokument" element={<DocumentViewer />} />
              </Route>
            </Route>

            <Route
              path="chemie"
              element={
                <React.Suspense fallback={<>...</>}>
                  <Outlet />
                </React.Suspense>
              }
            >
              <Route index element={<SubjectPage subject="chemistry" />} />
              <Route path=":lessonNumber" element={
                <React.Suspense fallback={<>...</>}>
                  <Outlet />
                </React.Suspense>

              }>
                <Route index element={<LessonPage subject="chemistry" />} />
                <Route path="video" element={<VideoPlayer />} />
                <Route path="dokument" element={<DocumentViewer />} />
              </Route>
            </Route>

            <Route
              path="biologie"
              element={
                <React.Suspense fallback={<>...</>}>
                  <Outlet />
                </React.Suspense>
              }
            >
              <Route index element={<SubjectPage subject="biology" />} />
              <Route path=":lessonNumber" element={
                <React.Suspense fallback={<>...</>}>
                  <Outlet />
                </React.Suspense>

              }>
                <Route index element={<LessonPage subject="biology" />} />
                <Route path="video" element={<VideoPlayer />} />
                <Route path="dokument" element={<DocumentViewer />} />
              </Route>
            </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <>
      <Menu />
      <Outlet />
      <Footer />
    </>
  );
}

function InternalLayout() {
  return (
    <>
      <Menu inner={true} />
      <Outlet />
    </>
  );
}
