import { Sale } from '../apiClient/Sale';
import { Users } from '../apiClient/Users';
import { Lessons } from '../apiClient/Lessons';
import { RequestParams } from '../apiClient/http-client';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { beUrl } from '../Constants';

export class ApiHelper {
  alert = useAlert();
  navigate = useNavigate();

  worker = (x : any) => {
    let t = localStorage.getItem("token");
    if (t === null) {
      return;
    }
    let p : RequestParams = {headers: {"Authorization" : t}};
    return p;
  }

  url = beUrl

  params = {
      baseUrl: this.url,
      securityWorker: this.worker 
  };

  userApi = new Users(this.params);

  lessonsApi = new Lessons(this.params);
  
  saleApi = new Sale(this.params);

  processErrors = async(x) => {
    if (x.status === 401) {
      localStorage.removeItem("token");
      this.navigate("/prihlaseni");
      return;
    }

    let text = await x.text();

    try {
        let object = JSON.parse(text);
        let errors = (object).errors;

        Object.keys(errors).forEach((i) => {
            errors[i].map((y : string) => {
                this.alert.error(y);
                return null;
            })
        })
    } catch {
        this.alert.error(text);
    }
  }
}
