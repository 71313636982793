import { useEffect, useState } from 'react';
import './styles/InfoBanner.scss'

export default function InfoBanner({title, children, click} : any) {
    return (
        <div className="infoBanner" >
            <div className="head" >
                <h1>
                    {title}
                </h1>
                <img src="/images/components/cross.png" alt="" onClick={click} />
            </div>
            <div className="text" >
                {children}
            </div>
        </div>
    );
}
