import { useState } from 'react';
import './styles/FormField.scss'

export default function FormField({label, password = false, value, handle} : any) {
    const[visible, setVisible] = useState(false);

    return (
        <div className="formField">
            <h3>
                {label}:
            </h3>

            <div>
                <input type={((password && !visible) ? "password" : "text")} value={value} onChange={(e : any) => handle(e.currentTarget.value)} />
                {password &&
                    <>
                    {("ontouchstart" in document.documentElement) ?
                        <img onTouchStart={() => setVisible(x => !x)} className="eye" src="/images/components/eye.png" alt="" />
                        :
                        <img onMouseDown={() => setVisible(true)} onMouseUp={() => setVisible(false)} className="eye" src="/images/components/eye.png" alt="" />
                    }
                    </>
                } 
            </div>
        </div>
    );
}
