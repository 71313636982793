import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useAlert } from 'react-alert';
import { useNavigate } from "react-router-dom";

export default function CheckoutComponent({clientSecret}) {
    const alert = useAlert();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if (!stripe) {
            return;
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent?.status) {
            case "succeeded":
                alert.success("Platba připravena")
            break;
            case "processing":
                alert.info("Platba se zpracovává")
            break;
            case "requires_payment_method":
            break;
            default:
                alert.error("Něco se pokazilo")
            break;
        }
        });
    }, [stripe]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        alert.info("Platba se zpracovává")
    
        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }
    
        setIsLoading(true);
    
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: "http://localhost/kurz"
        }});

        if (paymentIntent?.status === "succeeded") {
            alert.success("Platba proběhla úspěšně")
            navigate("/kurz")
            return;
        }
    
        if (error?.type === "card_error") {
            setMessage(error.message!);
        }

        alert.error("Nastala chyba")

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />

            <button className={"pay" + ((isLoading || !stripe || !elements) ? " disabled" : "")} id="submit">
                Zaplatit
            </button>

            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

