import { useDebugValue, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './styles/videoPlayer.scss'
import ReactPlayer from 'react-player';
import { filesUrl } from '../../Constants';
import useWindowDimensions from '../../hooks/size';
  
export default function VideoPlayer() {
    const videoUrl = filesUrl + localStorage.getItem("url");
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])
    
    return (
        <div className="videoPlayer">
            <ReactPlayer class="player" width={(width > 1250) ? 1200 : width - 15} height={"auto"} controls={true} url={videoUrl?.toString()} />

            <Link to=".." className="back"><img src="/images/common/back.png" alt="" /></Link>
        </div>
    );
}
