import './styles/NoMatch.scss'

export default function NoMatch() {
    return (
        <div>
            <h1>
                Stranka nenalezena
            </h1>
        </div>
    );
}
