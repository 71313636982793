import { useEffect, useState } from 'react';
import './styles/ProgressCircle.scss'

    export default function ProgressCircle({value, img = null} : any) {
    const preString = "radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#33658aff ";
    const postString = "%, #33658a40 0)";

    const[backgroundString, setBackgroundString] = useState(preString + value + postString);

    useEffect(() => {
        setBackgroundString(preString + value + postString);
    }, [value])

    return (
        <div className="progressCircle" style={{background: backgroundString}}>
            {img === null ?
                <h2>
                    {value}%
                </h2>
                :
                <img src={img} alt="" />
            }
        </div>
    );
}
