/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthenticateRequestModel,
  ChangeDataRequestModel,
  ChangePasswordRequestModel,
  RegisterRequestModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Users
   * @name LoginCreate
   * @request POST:/Users/Login
   * @secure
   */
  loginCreate = (data: AuthenticateRequestModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Users/Login`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name RegisterCreate
   * @request POST:/Users/Register
   * @secure
   */
  registerCreate = (data: RegisterRequestModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Users/Register`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name VerifyEmailCreate
   * @request POST:/Users/VerifyEmail/{userId}
   * @secure
   */
  verifyEmailCreate = (userId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Users/VerifyEmail/${userId}`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name ChangeDataCreate
   * @request POST:/Users/ChangeData
   * @secure
   */
  changeDataCreate = (data: ChangeDataRequestModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Users/ChangeData`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name ChangePasswordCreate
   * @request POST:/Users/ChangePassword
   * @secure
   */
  changePasswordCreate = (data: ChangePasswordRequestModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Users/ChangePassword`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users
   * @name GetDataList
   * @request GET:/Users/GetData
   * @secure
   */
  getDataList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Users/GetData`,
      method: "GET",
      secure: true,
      ...params,
    });
}
