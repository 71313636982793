import './styles/Product.scss'

export default function Product({title, number, disabled = false, price, prevPrice = null, children, img, clicked = null} : any) {
    return (
        <div className={"product " + (disabled ? "disabled" : "")}>
            <h1 className="title">
                {title}
            </h1>
            <div className="priceLine">
                <h2 className={(prevPrice !== null ? "cross" : "")}>
                    {(prevPrice !== null) ? prevPrice : price} Kč
                </h2>

                {(prevPrice !== null) &&
                    <div className="sale">
                        <h3>
                            {price} 
                            <br/>
                            Kč
                        </h3>
                    </div>
                }
            </div>
            <img className="image" src={img} alt="" />
            <div className="children">
                {children}
            </div>
            <div className="button" onClick={() => {if(!disabled) {clicked(number)}}} >
                <h2>
                    Do košíku
                </h2>
            </div>
        </div>
    );
}
