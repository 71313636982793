import { useEffect, useState } from 'react';
import { Link, redirect, useNavigate, useParams} from "react-router-dom";
import './styles/lessonPage.scss'
import Collapsible from '../../components/Collapsible';
import { ApiHelper } from '../../apiHelper/ApiHelper';

export default function LessonPage({subject} : any) {
    const [materials, setMaterial] = useState([]);
    const [bonuses, setBonuses] = useState([]);
    const [tests, setTests] = useState([]);
    const [videos, setVideos] = useState([]);
    const [title, setTitle] = useState("");
    const [id, setId] = useState("");
    const [completed, setCompleted] = useState(false);
    const [disable, setDisable] = useState(false);
    const [fetched, setFetched] = useState(false);
    const navigate = useNavigate();
    let { lessonNumber } = useParams();

    const api = new ApiHelper();

    async function fetch() {
        let lesson;

        try {
            const res = await api.lessonsApi.getLessonsDetail(subject);
            const proc = await res.json();

            lesson = (proc.lessons.filter((e : any) => ((e.number.toString() === lessonNumber))))[0];

            setTitle(lesson.title);
            setMaterial(JSON.parse(lesson.materials));
            setBonuses(JSON.parse(lesson.bonuses));
            setTests(JSON.parse(lesson.tests));
            setVideos(JSON.parse(lesson.videos));
            setId(lesson.lessonId);
            setCompleted(lesson.isCompleted);
        } catch (x : any) {
            api.processErrors(x)
        }
    }
  
    useEffect(() => {
        if (!fetched) {
            fetch();
            setFetched(false);
        }
    }, []);

    const ChangeState = async () => {
        if (disable) {
            return;
        }

        setCompleted(true)
        setDisable(true);

        try {
            await api.lessonsApi.LessonStateCreate({lessonId: id, completed: !completed});
        } catch (x : any) {
            api.processErrors(x)
        }

        try {
            const res = await api.lessonsApi.getLessonsDetail(subject);
            const proc = await res.json();

            let lesson = (proc.lessons.filter((e : any) => ((e.number.toString() === lessonNumber))))[0];

            setCompleted(lesson.isCompleted);
        } catch (x : any) {
            api.processErrors(x)
        }

        setDisable(false);
    }

    const openFile = (url) => {
        localStorage.setItem("url", url);
        navigate("dokument")
    }
  
    const openVideo = (url) => {
        localStorage.setItem("url", url);
        navigate("video")
    }

    const openLink = (url) => {
        window.location.replace(url)
    }

    return (
        <div className="lessonPage">
            <div className="title" onClick={() => ChangeState()}>
                <h1>
                    {title} 
                </h1>

                {completed ?
                    <img src="/images/pages/completed.png" alt="" />
                    :
                    <img src="/images/pages/incompleted.png" alt="" />
                }
            </div>

            <Collapsible title="Materiály">
                {materials.map((e : any) => {
                    return (
                        <div className="line" onClick={() => openFile(e.url)}>
                            <img src="/images/pages/text.png" alt="" />
                            <h2>{e.name}</h2>
                        </div>
                    )
                })}
            </Collapsible>

            <Collapsible title="Videa">
                {videos.map((e : any) => {
                    return (
                        <div className="line" onClick={() => openVideo(e.url)}>
                            <img src="/images/pages/play.png" alt="" />
                            <h2>{e.name}</h2>
                        </div>
                    )
                })}
            </Collapsible>

            <Collapsible title="Testy">
                {tests.map((e : any) => {
                    return (
                        <div className="line" onClick={() => openFile(e.url)}>
                            <img src="/images/pages/x.png" alt="" />
                            <h2>{e.name}</h2>
                        </div>
                    )
                })}
            </Collapsible>

            <Collapsible title="Odkazy">
                {bonuses.map((e : any) => {
                    return (
                        <div className="line" onClick={() => openLink(e.url)}>
                            <img src="/images/pages/link.png" alt="" />
                            <h2>{e.name}</h2>
                        </div>
                    )
                })}
            </Collapsible>

            <Link to=".." className="back"><img src="/images/common/back.png" alt="" /></Link>
        </div>
    );
}
