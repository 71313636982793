import { useState } from 'react';
import FormField from '../../components/FormField';
import './styles/SignIn.scss'
import { useNavigate } from 'react-router-dom';
import { ApiHelper } from '../../apiHelper/ApiHelper';

export default function SignIn() {
    const[email, setEmail] = useState("");
    const[password, setPassword] = useState("");
    const navigate = useNavigate();

    const api = new ApiHelper();

    async function process() {
        try {
            let res = await api.userApi.loginCreate({username: email, password: password});
            let token = (await res.json()).token;
            localStorage.removeItem("token");
            localStorage.setItem("token", "Bearer " + token);
            navigate("/kurz");
        } catch (x : any) {
            api.processErrors(x);
        }
    }

    return (
        <div className="signIn">
            <div className="container" >
                <h1 className="title">
                    Přihlášení
                </h1>

                <FormField label="email" value={email} handle={setEmail} />
                <FormField label="heslo" value={password} handle={setPassword} password={true} />

                <div className="button" onClick={async () => await process()}>
                    <h2>
                        PŘIHLÁSIT SE
                    </h2>
                </div>
            </div>
        </div>
    );
}
