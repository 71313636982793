import { Link, useNavigate } from 'react-router-dom';
import './styles/Menu.scss'
import { useEffect, useState } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
  
export default function Menu({inner} : any) {
    const[mobile, setMobile] = useState(getWindowDimensions().width < 950);
    const[invisible, setInvisible] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        function handleResize() {
            setMobile((getWindowDimensions().width < 950) ? true : false);
        }
  
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={"menu " + ((mobile) ? "mobile" : "")}>
            <Link to="/" >
                <img className={"logo " + ((mobile) ? "mobile" : "")} src="/images/common/logo.png" alt="" /> 
            </Link>

            {
                mobile && <img onClick={() => setInvisible(!invisible)} className={"button " + (invisible ? "invisible" : "")} src="/images/components/button.png" alt="" />
            }

            <div onClick={() => setInvisible(!invisible)} className={("links " + ((mobile) ? "mobile " : "") + ((invisible) ? "invisible" : ""))}>
                {!inner ? 
                    <>
                        <Link to="/kontakty" >Kontakty</Link>
                        <Link to={(localStorage.getItem("token") === null) ? "/prihlaseni" : "/kurz"}><p>Kurz</p></Link>
                    </>
                    :
                    <>
                        <Link to="/kurz" >Domů</Link>
                        <Link to="/kurz/nakup" >Nákup</Link>
                        <Link to="/kurz/ucet" ><p>Účet</p></Link>
                    </>
                }
            </div>
        </div>
    );
}
