import { useState } from 'react';
import FormField from '../../components/FormField';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import './styles/SignUp.scss'
import { ApiHelper } from '../../apiHelper/ApiHelper';
import InfoBanner from '../../components/InfoBanner';

export default function SignUp() {
    const[name, setName] = useState("");
    const[surname, setSurname] = useState("");
    const[email, setEmail] = useState("");
    const[password, setPassword] = useState("");
    const[passwordRep, setPasswordRep] = useState("");
    const[confirmation, setConfirmation] = useState(false);
    const[banner, setBanner] = useState(true);
    const alert = useAlert();
    const navigate = useNavigate();

    const api = new ApiHelper();

    async function process() {
        if (password !== passwordRep) {
            alert.error("Hesla nejsou shodná");
            return;
        }

        if (!confirmation) {
            alert.error("Bez souhlasu se nelze registrovat");
            return;
        }

        try {
            await api.userApi.registerCreate({email: email, password: password, name: name, surname: surname});
            alert.success("Registrace proběhla úspěšně");
            alert.info("Před přihlášením je třeba ověřit email! Očekávejte ověřovací email ve Vašá mailové schránce. Může přijít až za půl hodiny od registrace. Pokud se tak nestane, kontaktujte nás.");
            navigate("/prihlaseni");
        } catch (x : any) {
            api.processErrors(x);
        }
    }

    return (
        <>
            <div className="signUp">
                {banner &&
                    <InfoBanner title="Jak na to?" click={() => setBanner(false)}>
                        <div className="diagram">
                            <img src="/images/pages/diagrams/Diagram-mobil.png" alt="" />
                        </div>
                    </InfoBanner>
                }

                <div className="container" >
                    <h1 className="title">
                        Registrace
                    </h1>

                    <FormField label="jméno" value={name} handle={setName} />
                    <FormField label="příjmení" value={surname} handle={setSurname} />
                    <FormField label="email" value={email} handle={setEmail} />
                    <FormField label="heslo" value={password} handle={setPassword} password={true} />
                    <FormField label="heslo znovu" value={passwordRep} handle={setPasswordRep} password={true} />

                    <div className="conf">
                        <input type='checkbox' checked={confirmation} onChange={() => setConfirmation((c : any) => !c)} />
                        <h3>Souhlasim s obchodními podmínkami a podmínkami GDPR přístupnými na stránce níže</h3>
                    </div>

                    <div className={"button"} onClick={() => process()}>
                        <h2>
                            REGISTROVAT SE
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

