import { Link, useNavigate } from 'react-router-dom';
import './styles/Footer.scss'
import { useState } from 'react';
import InfoBanner from './InfoBanner';

const banners = {businessConditions: "businessConditions", gdpr: "gdpr"}

export default function Footer({inner} : any) {
    const navigate = useNavigate();
    const [banner, setBanner] = useState<string | null>(null);

    return (
        <>
        {
            banner === banners.businessConditions &&
            <InfoBanner title="Obchodní podmínky" click={() => setBanner(null)}>
                <iframe src="/documents/op.pdf"></iframe>
            </InfoBanner>
        }
        {
            banner === banners.gdpr &&
            <InfoBanner title="GDPR" click={() => setBanner(null)}>
                <iframe src="/documents/gdpr.pdf"></iframe>
            </InfoBanner>
        }
        <div className="footer">
            <div className="holder">
                <div className="footerBlock">
                    <h2 onClick={() => navigate("/kurz")}>
                        Kurz
                    </h2>
                    <h2 onClick={() => navigate("/kontakty")}>
                        Kontakty
                    </h2>
                    <h2 onClick={() => setBanner(banners.businessConditions)}>
                        Obchodní podmínky
                    </h2>
                    <h2 onClick={() => setBanner(banners.gdpr)}>
                        GDPR
                    </h2>
                </div>
                <img src="/images/components/arrow.png" className="arrow" alt="" onClick={() =>  window.scrollTo(0, 0)}/>
            </div>
        </div>
        </>
    );
}
