import { useDebugValue, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './styles/documentViewer.scss'
import ReactPlayer from 'react-player';
import { Document, Page, pdfjs } from 'react-pdf';
import useWindowDimensions from '../../hooks/size';
import { filesUrl } from '../../Constants';
import { PDFObject } from 'react-pdfobject';
  
export default function DocumentViewer() {
    const [numPages, setNumPages] = useState<number>();
    const { height, width } = useWindowDimensions();
    const[scale, setScale] = useState(width/650);
    const [pos, setPos] = useState({ x: 0, y: 0});

    let pdfUrl = filesUrl + localStorage.getItem("url");

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    useEffect(() => {
        const handleContextmenu = e => {
            //e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [ ])
    
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <div className="documentViewer" >
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.apply(null, Array(numPages))
                    .map((x, i)=>i+1)
                    .map(page => <Page pageNumber={page} scale={scale} renderTextLayer={false} renderAnnotationLayer={false}/>)}
            </Document>
            
            <input type="range" min={width/14.00} max={width/2.5 - width/14.00} value={scale*100} className="slider" onChange={(x) => setScale(parseFloat(x.target.value)/100)}/>

            <Link to=".." className="back"><img src="/images/common/back.png" alt="" /></Link>
        </div>
    )
}
