import { useState } from "react";

export default function Collapsible({title, children} : any) {
    const[collapsed, setCollapsed] = useState(false)

    return (
        <div className={"block " + (collapsed ? "collapsed" : "")} >
            <div className="head" onClick={() => {setCollapsed(!collapsed)}} >
                <h1>
                    {title}
                </h1>
                <img src="/images/pages/arrow.png" alt="" />
            </div>
            <div className="lines" >
                {children}
            </div>
        </div>
    );
}
